import React from 'react'
import axios from 'axios'

import ContactForm from '../components/form';

export default class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
  }

  componentDidMount() {
    try {
      const WOW = require('wowjs');
      const wow = new WOW.WOW();
      wow.init();
    } catch (e) {
      console.error(e);
    }

    axios
    .get(`${process.env.API_URL}/umbraco/api/contact/get`)
    .then(result => {
      this.setState({
        data: result.data,
      })
    })
    .catch(error => {})
  }

  render() {
    const { data } = this.state;
    let map = '';
    let phone = '';
    let address = '';
    let email = '';

    if(data) {
      map = data.Map;
      phone = data.Phone;
      address = data.Address;
      email = data.Email;
    }

    return (
      <div>
        <section className="section-top">
          <div className="container">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="section-top-title">
                <h2>Contactos</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">

            </div>
          </div>
        </section>
        <div id="map" dangerouslySetInnerHTML={{__html: map}}>
        </div>
         <section className="footer_contact_area section-padding">
          <div className="container-fluid">
            <div className="row">
              <div className="section-title text-center wow zoomIn">
                <h2>Contacte-nos</h2>
                <span></span>
              </div>
              <div className="col-md-8 col-md-offset-2 col-sm-12 col-xs-12">
                <ContactForm />
              </div>
            </div>
          </div>
        </section>
        <div className="contact-address section-padding">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="single single_address single_activites_color_two">
                  <i className="fa fa-phone"></i>
                  <p><a href={`tel:${phone}`}>{phone}</a></p>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="single single_address single_activites_color_two">
                  <i className="fa fa-rocket"></i>
                  <p>{address}</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="single single_address single_activites_color_two">
                  <i className="fa fa-envelope"></i>
                  <p><a href={`mailto:${email}`}>{email}</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
